import './App.css';
import { Box, Button, Card, Center, DrawerContent, Flex, HStack, Menu, MenuList, MenuItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, MenuButton, Drawer, IconButton, DrawerCloseButton, DrawerOverlay, useBreakpointValue, VStack, Text, useWhyDidYouUpdate, CardBody, theme, Stack, StackDirection, useDisclosure } from '@chakra-ui/react';
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { NodeList } from './components/NodeList';
import { LoopList } from './components/LoopList';
import { Node, NodeProps, Point } from './model/node';
import { Edge, EdgeProps } from './model/edge';
import { Loop, LoopProps } from './model/loop';
import { Canvas } from './components/Canvas';
import { EdgeList } from './components/EdgeList';
import { useGmsh } from './hooks/useGmsh';
import { BoundaryCondition, BoundaryConditionProps } from './model/boundarycondition';
import { BoundaryConditionList } from './BoundaryConditionList';
import { Colormap, useTraceLib } from './hooks/useTraceLib';
import { useDeletableState } from './hooks/useDeletableState';
import { useAdminMode } from './hooks/useAdminMode';
import { Help } from './components/Help';

import { PlaneStressAnalysis } from './pkg/fem/emtrace-types';
import { v4 } from 'uuid';
import { SimpleBoundaryCondition } from './components/SimpleBoundaryCondition';
import { HamburgerIcon, CheckIcon, CloseIcon, QuestionIcon, SettingsIcon } from '@chakra-ui/icons';
import { isMobile } from 'react-device-detect';

import { Puzzle } from './model/puzzle';
import { PopoverLevelFinished } from './components/PopoverLevelFinished';
import { Colorbar } from './components/Colorbar';
import { useWindowHeight } from './hooks/useWindowHeight';

const puzzleData: Array<Puzzle> = [
  { id: 0, isSolved: false, jsonFile: "1_a378319a-4be7-46eb-8eeb-98f25e701a35.json" },
  { id: 1, isSolved: false, jsonFile: "2_a81ba2bf-a4dc-4798-95c1-047a6f5513f0.json" },
  { id: 2, isSolved: false, jsonFile: "3_ea29d411-61d6-4263-be1b-74ddbab72be7.json" },
  { id: 3, isSolved: false, jsonFile: "4_493f4e44-9d21-4ae8-8bc6-559a343b3019.json" },
  { id: 4, isSolved: false, jsonFile: "5_35dd196b-36de-433b-a2b3-4a7d786afb84.json" },
  { id: 5, isSolved: false, jsonFile: "6_ef740dc1-c458-4282-94bc-a3a4c415fc3d.json" },
  { id: 6, isSolved: false, jsonFile: "7_5b102875-642f-4c01-9182-2554902d73ca.json" },
  { id: 7, isSolved: false, jsonFile: "8_39a3975f-cc49-42fb-8b1f-05c5e66f23fc.json" },
  { id: 8, isSolved: false, jsonFile: "9_3f9dd8f4-8f4a-43a0-9147-e8a1df175b64.json" },
  { id: 9, isSolved: false, jsonFile: "10_3201bd62-1ef9-4f66-8fcd-2a0a454cc27b.json" },
  { id: 10, isSolved: false, jsonFile: "11_53ed544b-6b58-4513-afd1-e9c7bbb0ee3f.json" },
];

const defaultAdminMode = false;

export class Model {
  nodes: Array<Node>;
  edges: Array<Edge>;
  loops: Array<Loop>;
  boundaryConditions: Array<BoundaryCondition>;

  constructor(nodes?: Array<Node>, edges?: Array<Edge>, loops?: Array<Loop>, boundaryConditions?: Array<BoundaryCondition>) {
    this.nodes = nodes ? nodes : new Array<Node>();
    this.edges = edges ? edges : new Array<Edge>();
    if (loops === undefined || loops.length === 0) {
      this.loops = [new Loop({ edges: [] })];
    } else {
      this.loops = loops;
    }

    this.boundaryConditions = boundaryConditions ? boundaryConditions : new Array<BoundaryCondition>();
  }

  addNode(node: Node): Model {
    return new Model([...this.nodes, node], [...this.edges], [...this.loops], [...this.boundaryConditions]);
  }

  addEdge(edge: Edge): Model {
    return new Model([...this.nodes], [...this.edges, edge], [...this.loops], [...this.boundaryConditions]);
  }

  addLoop(loop: Loop): Model {
    return new Model([...this.nodes], [...this.edges], [...this.loops, loop], [...this.boundaryConditions]);
  }

  addBoundaryCondition(bc: BoundaryCondition): Model {
    return new Model([...this.nodes], [...this.edges], [...this.loops], [...this.boundaryConditions, bc]);
  }

  removeNode(node: Node): Model {
    const filteredNodes = this.nodes.filter((n: Node) => {
      return n !== node;
    });
    return new Model(filteredNodes, [...this.edges], [...this.loops], [...this.boundaryConditions]);
  }

  removeEdge(edge: Edge): Model {
    const filteredEdges = this.edges.filter((e: Edge) => {
      return e !== edge;
    });
    return new Model([...this.nodes], filteredEdges, [...this.loops], [...this.boundaryConditions]);
  }

  removeLoop(loop: Loop): Model {
    const filteredLoops = this.loops.filter((l: Loop) => {
      return l !== loop;
    });

    return new Model([...this.nodes], [...this.edges], filteredLoops, [...this.boundaryConditions]);
  }

  removeBoundaryCondition(boundaryCondition: BoundaryCondition): Model {
    const filteredBCs = this.boundaryConditions.filter((bc: BoundaryCondition) => {
      return bc !== boundaryCondition;
    });

    return new Model([...this.nodes], [...this.edges], [...this.loops], filteredBCs);
  }

  updateNode(node: Node, props: NodeProps): Model {
    const newNodes = this.nodes.map((n: Node) => {
      if (n === node) {
        return new Node({ ...node.props, ...props })
      }
      return n;
    });
    return new Model(newNodes, [...this.edges], [...this.loops], [...this.boundaryConditions]);
  }

  updateEdge(edge: Edge, props: EdgeProps): Model {
    const newEdges = this.edges.map((e: Edge) => {
      if (e === edge) {
        return new Edge({ ...edge.props, ...props });
      }
      return e;
    });
    return new Model([...this.nodes], newEdges, [...this.loops], [...this.boundaryConditions]);
  }

  updateLoop(loop: Loop, props: LoopProps): Model {
    const newLoops = this.loops.map((l: Loop) => {
      if (l === loop) {
        return new Loop({ ...loop.props, ...props });
      }
      return l;
    });
    return new Model([...this.nodes], [...this.edges], newLoops, [...this.boundaryConditions]);
  };

  updateBoundaryCondition(boundaryCondition: BoundaryCondition, props: BoundaryConditionProps): Model {
    const newBCs = this.boundaryConditions.map((bc: BoundaryCondition) => {
      if (boundaryCondition === bc) {
        return new BoundaryCondition({ ...bc.props, ...props });
      }
      return bc;
    });
    return new Model([...this.nodes], [...this.edges], [...this.loops], newBCs);
  };

  toGeometryJSON(): string {
    return JSON.stringify(
      {
        nodes: this.nodes.map((n: Node) => { return { id: n._id, x: n.coordinates.x, y: n.coordinates.y }; }),
        edges: this.edges.map((e: Edge) => {
          return {
            id: e.id,
            first_node: e.start,
            second_node: e.end,
            geometry: e._geometry.type === "arc" ? { type: "arc", center: e._geometry.data.center } : { type: "straight" }
          };
        }),
        loops: this.loops.map((l: Loop) => { return { id: l.id, edges: l._edges } })
      }
    );
  }
}

export enum InteractionMode {
  Select,
  NodeDrawing,
  EdgeDrawing,
  ArcDrawing,
  LoopCreation,
  SelectBCGeometry,
  Trace,
  GameBoundaryCondition // This mode will be used in the tracerace game
};

interface MeshData {
  svg: string,
  json: string,
  uuidToNodes: Map<string, Array<number>> | null
};

interface SerializableUUIDToNodes {
  [key: string]: Array<number>
};

interface IOModel {
  model: Model,
  mesh: {
    svg: string,
    json: string,
    uuidToNodes: SerializableUUIDToNodes
  }
  seedPoints: Array<Point>
};

type NodeOrEdge =
  { type: "node", data: Node } |
  { type: "edge", data: Edge } |
  { type: "none" };

export const getNodeOrEdge = (id: string, model: Model): NodeOrEdge => {
  let selected: NodeOrEdge = { type: "none" };

  const selectedNode = model.nodes.find((n: Node) => n.id === id);
  if (selectedNode !== undefined) selected = { type: "node", data: selectedNode };

  const selectedEdge = model.edges.find((e: Edge) => e.id === id);
  if (selectedEdge !== undefined) selected = { type: "edge", data: selectedEdge };

  return selected;
}

const getBoundaryCondition = (entity: Node | Edge, m: Model) => {
  const bc = m.boundaryConditions.find((bc: BoundaryCondition) => bc.geometry_ === entity._id);
  return bc;
}

function App() {

  const buttonSize = useBreakpointValue({ base: 'xs', lg: 'md' });
  const colorBarSize = useBreakpointValue({base: "40px", lg: "50px"}, {ssr: false});
  const mainUiStackDirection : StackDirection | undefined = useBreakpointValue({base: "column", lg: "row", fallback: "row"}, {ssr: false});
  const windowHeight = useWindowHeight();

  const [model, setModel] = useState<Model>(new Model());
  const [interactionMode, setInteractionMode] = useState<InteractionMode>(InteractionMode.GameBoundaryCondition);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [hilighted, setHilighted] = useState<Array<string>>([]);
  const [gmshReady, generateMesh] = useGmsh();
  const [colorMap, setColorMap] = useState<Colormap>(Colormap.turbo);
  const [meshData, setMeshData] = useState<MeshData>({ svg: "", json: "", uuidToNodes: null });
  const [traceLibReady, runPlaneStressAnalysis, computeTrajectories, getColorMap] = useTraceLib();
  const [simulationObject, setSimulationObject] = useDeletableState<PlaneStressAnalysis | null>(null);
  const [referenceSolution, setReferenceSolution] = useDeletableState<PlaneStressAnalysis | null>(null);
  const [seedPoints, setSeedPoints] = useState<Array<Point>>([]);
  const [svgTrajectories, setSVGTrajectories] = useState<string>("");
  const [referenceTrajectories, setReferenceTrajectories] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSolutionCorrect, setIsSolutionCorrect] = useState<boolean>(false);
  const {isOpen: isHelpOpen, onClose: onHelpClose, onOpen: onHelpOpen} = useDisclosure();

  const [puzzles, setPuzzles] = useState<Array<Puzzle>>(puzzleData);
  const [activePuzzleId, setActivePuzzleId] = useState<number>(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const toggleSideBar = () => setIsDrawerOpen((prev: boolean) => !prev);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isAdminMode = useAdminMode();

  const addNode = (n: Node) => {
    setModel((prev: Model) => {
      return prev.addNode(n);
    });
  };

  const removeNode = (n: Node) => {
    setModel((prev: Model) => {
      return prev.removeNode(n);
    });
  };

  const updateNode = (n: Node, props: NodeProps) => {
    setModel((prev: Model) => {
      return prev.updateNode(n, props);
    });
  };

  const addEdge = (e: Edge) => {
    setModel((prev: Model) => {
      return prev.addEdge(e);
    });
  }

  const removeEdge = (e: Edge) => {
    setModel((prev: Model) => {
      return prev.removeEdge(e);
    });
  }

  const updateEdge = (e: Edge, p: EdgeProps) => {
    setModel((prev: Model) => {
      return prev.updateEdge(e, p);
    });
  }

  const addLoop = (l: Loop) => {
    setModel((prev: Model) => {
      return prev.addLoop(l);
    });
  };

  const removeLoop = (l: Loop) => {
    setModel((prev: Model) => {
      return prev.removeLoop(l);
    });
  };

  const updateLoop = (l: Loop, p: LoopProps) => {
    setModel((prev: Model) => {
      return prev.updateLoop(l, p);
    });
  }

  const addBoundaryCondition = (bc: BoundaryCondition) => {
    setModel((prev: Model) => {
      return prev.addBoundaryCondition(bc);
    });
  };

  const removeBoundaryCondition = (bc: BoundaryCondition) => {
    setModel((prev: Model) => {
      return prev.removeBoundaryCondition(bc);
    });
  };

  const updateBoundaryCondition = (bc: BoundaryCondition, p: BoundaryConditionProps) => {
    setModel((prev: Model) => {
      return prev.updateBoundaryCondition(bc, p);
    });
  }

  const addSelected = (id: string) => {
    setSelected((prev: Array<string>) => {
      if (prev.length === 1 && interactionMode === InteractionMode.EdgeDrawing) {
        if (model.nodes.find((n: Node) => n.id === id)) {
          addEdge(new Edge({ start: prev[0], end: id, geometry: { type: "straight", data: [] } }));
        }
        return [];
      }
      if (interactionMode === InteractionMode.ArcDrawing) {
        const node = model.nodes.find((n: Node) => n.id === id);
        if (node === undefined) return [...prev];
        if (prev.length < 2) {
          return [...prev, id];
        }
        if (prev.length === 2) {
          addEdge(new Edge({ start: prev[0], end: prev[1], geometry: { type: "arc", data: { center: id } } }));
          return [];
        }
      }
      if (interactionMode === InteractionMode.LoopCreation) {
        const edge = model.edges.find((e: Edge) => e.id === id);
        if (edge === undefined) return [...prev];

        const lastLoop = model.loops[model.loops.length - 1];
        // If there are no edges in the loop, this is the first one
        if (lastLoop._edges.length === 0) {
          updateLoop(lastLoop, { ...lastLoop.props, edges: [edge.id] })
          return [id];
        }

        const lastEdgeId = lastLoop._edges[lastLoop._edges.length - 1];
        const lastEdge = model.edges.find((e: Edge) => e.id === lastEdgeId);
        if (lastEdge === undefined) return [...prev];

        const lastNodeId = lastEdge.end;
        if (lastNodeId === edge.start) {
          updateLoop(lastLoop, { edges: [...lastLoop._edges, edge.id] });
          const firstEdgeId = lastLoop._edges[0];
          const firstEdge = model.edges.find((e: Edge) => e.id === firstEdgeId);
          if (firstEdge === undefined) return [...prev];
          const firstNode = firstEdge.start;
          if (firstNode === edge.end) {
            addLoop(new Loop({ edges: [] }));
            return [];
          }
          return [...prev, id];
        }
      }
      if (interactionMode === InteractionMode.SelectBCGeometry) {
        // There should be exactly one boundary condition selected
        if (selected.length !== 1) {
          setInteractionMode(InteractionMode.Select);
          return [];
        }

        const selectedBc = model.boundaryConditions.find((bc: BoundaryCondition) => {
          return selected[0] === bc.id_;
        });

        if (selectedBc === undefined) return [];

        const selectedEntity = [...model.edges, ...model.nodes].find((entity: Node | Edge) => {
          return entity._id === id;
        });

        if (selectedEntity === undefined) return [];

        updateBoundaryCondition(selectedBc, { ...selectedBc.props, geometry: selectedEntity._id });
        setInteractionMode(InteractionMode.Select);
        return [];
      }
      return [id];
    });
  };

  const createInteractiveBoundaryCondition = (geometryId: string) => {
    if (interactionMode === InteractionMode.GameBoundaryCondition) {
      const selected = getNodeOrEdge(geometryId, model);
      if (selected.type === "none") return;
      let boundaryCondition = getBoundaryCondition(selected.data, model);

      // The entity doesn't have a boundary condition, so we create a new one here
      if (boundaryCondition === undefined) {
        boundaryCondition = new BoundaryCondition({ kind: { type: "fixed", data: { u: 0, v: 0 } }, geometry: geometryId });
        addBoundaryCondition(boundaryCondition);
      }
      setSelected([boundaryCondition.id_]);
    }
  }

  const clearSelected = () => {
    setSelected([]);
  };

  const addHilighted = (id: string) => {
    setHilighted((prev: Array<string>) => {
      return [...prev, id];
    });
  };

  const clearHilighted = () => {
    setHilighted([]);
  }

  const clearAll = () => {
    if (simulationObject !== null) {
      simulationObject.clearTraces();
    }
    setModel(new Model());
    setMeshData({ svg: "", json: "", uuidToNodes: null });
    setSeedPoints([]);
    setSVGTrajectories("");
    setReferenceTrajectories("");
    setSimulationObject(null);
    setReferenceSolution(null);
  };

  const startBcGeometrySelection = (id: string) => {
    setSelected([id]);
    setInteractionMode(InteractionMode.SelectBCGeometry);
  };

  const addSeedPoint = (p: Point) => {
    setSeedPoints((prev) => [...prev, p]);
  };

  useEffect(() => {
    if (simulationObject === null || seedPoints.length === 0) return;
    const trajectories = computeTrajectories(simulationObject, seedPoints, 1.0, 1000, colorMap);
    if (trajectories === null) return;
    setSVGTrajectories(trajectories);
  }, [seedPoints, computeTrajectories, simulationObject, colorMap]);

  useEffect(() => {
    if (!traceLibReady) return;
    if (simulationObject === null || referenceSolution === null) {
      return;
    }
    const correctSolution = simulationObject.compareSolution(referenceSolution, 1E-2)
    setIsSolutionCorrect(correctSolution);
    if (correctSolution) {
      setPuzzles((previous) => {
        return previous.map((puzzle) => puzzle.id === activePuzzleId ? { ...puzzle, isSolved: correctSolution } : puzzle);
      });
      setIsPopoverOpen((prev) => true);
    }
  }, [simulationObject, referenceSolution, activePuzzleId, setSimulationObject, traceLibReady]);

  useEffect(() => {
    if (referenceSolution === null || seedPoints.length === 0) return;
    const trajectories = computeTrajectories(referenceSolution, seedPoints, 1.0, 1000, colorMap);
    if (trajectories === null) return;
    setReferenceTrajectories(trajectories);
  }, [seedPoints, computeTrajectories, referenceSolution, colorMap]);

  const onSaveToJSON = () => {

    const serializableUuidToNodes: SerializableUUIDToNodes = {};
    meshData.uuidToNodes?.forEach((value: Array<number>, key: string) => {
      serializableUuidToNodes[key] = value;
    });

    const ioModel: IOModel = {
      mesh: {
        json: meshData.json,
        svg: meshData.svg,
        uuidToNodes: serializableUuidToNodes
      },
      model: model,
      seedPoints: seedPoints
    };

    const json = JSON.stringify(ioModel);
    const blob = new Blob([json], { type: "application/json" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = v4() + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onLoadJSON = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const result = e.target?.result;
        if (typeof result === 'string') {
          const json = JSON.parse(result) as IOModel;
          const nodes = json.model.nodes.map((n: Node) => {
            return new Node({ id: n._id, coordinates: n._coordinates });
          });
          const edges = json.model.edges.map((e: Edge) => {
            return new Edge({ id: e._id, start: e._start, end: e._end, geometry: e._geometry });
          });
          const loops = json.model.loops.map((l: Loop) => {
            return new Loop({ id: l.id, edges: l._edges });
          });
          const bcs = json.model.boundaryConditions.map((b: BoundaryCondition) => {
            return new BoundaryCondition({ id: b.id_, geometry: b.geometry_, kind: b.kind_ });
          });

          const referenceModel = new Model(nodes, edges, loops, bcs);
          const model = new Model(nodes, edges, loops, bcs);
          setModel(model);

          const uuidToNodes = new Map<string, Array<number>>();
          Object.keys(json.mesh.uuidToNodes).forEach(key => {
            uuidToNodes.set(key, json.mesh.uuidToNodes[key]);
          });
          setMeshData({ ...json.mesh, uuidToNodes: uuidToNodes });
          if (json.seedPoints === undefined) {
            json.seedPoints = [];
          }
          setSVGTrajectories("");
          setSimulationObject(null);
          const referenceResult = runPlaneStressAnalysis(json.mesh.json, referenceModel.boundaryConditions, uuidToNodes, referenceModel.nodes, referenceModel.edges);
          setReferenceSolution(referenceResult);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  };

  const handleLoadPremade = useCallback((id: number) => {
    const puzzle = puzzleData.find((val: Puzzle) => {
      return val.id === id;
    });
    if (puzzle === undefined) return;

    const fileName = puzzle.jsonFile;

    fetch(fileName).then(async (res: Response) => {
      try {
        const result = await res.text();
        if (typeof result === 'string') {
          const json = JSON.parse(result) as IOModel;
          const nodes = json.model.nodes.map((n: Node) => {
            return new Node({ id: n._id, coordinates: n._coordinates });
          });
          const edges = json.model.edges.map((e: Edge) => {
            return new Edge({ id: e._id, start: e._start, end: e._end, geometry: e._geometry });
          });
          const loops = json.model.loops.map((l: Loop) => {
            return new Loop({ id: l.id, edges: l._edges });
          });
          const bcs = json.model.boundaryConditions.map((b: BoundaryCondition) => {
            return new BoundaryCondition({ id: b.id_, geometry: b.geometry_, kind: b.kind_ });
          });

          const referenceModel = new Model(nodes, edges, loops, bcs);
          const model = new Model(nodes, edges, loops, []);
          setModel(model);

          const uuidToNodes = new Map<string, Array<number>>();
          Object.keys(json.mesh.uuidToNodes).forEach(key => {
            uuidToNodes.set(key, json.mesh.uuidToNodes[key]);
          });
          setMeshData({ ...json.mesh, uuidToNodes: uuidToNodes });
          if (json.seedPoints === undefined) {
            json.seedPoints = [];
          }
          setSeedPoints(json.seedPoints);
          setSVGTrajectories("");
          setSimulationObject(null);
          const referenceResult = runPlaneStressAnalysis(json.mesh.json, referenceModel.boundaryConditions, uuidToNodes, referenceModel.nodes, referenceModel.edges);
          setReferenceSolution(referenceResult);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }).catch((reason: any) => {
      console.log(reason.toString());
    });
  }, [runPlaneStressAnalysis, setReferenceSolution, setSimulationObject]);

  useEffect(() => {
    handleLoadPremade(activePuzzleId);
  }, [activePuzzleId, handleLoadPremade]);

  const colors = useMemo(() => {
    if(!traceLibReady) return new Array<string>();
    return getColorMap(colorMap);
  }, [traceLibReady, getColorMap, colorMap])

  return (
    <HStack height="100%">
      <Drawer isOpen={isDrawerOpen} onClose={toggleSideBar} placement='left' size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <Box justifyContent="flex-end">
            <DrawerCloseButton position="static" />
          </Box>
          <Accordion flex="1" height="100vh" allowMultiple overflowY="scroll">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  Nodes
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <NodeList
                  nodes={model.nodes}
                  addNode={addNode}
                  removeNode={removeNode}
                  updateNode={updateNode}
                  selected={selected}
                  addSelected={addSelected}
                  clearSelected={clearSelected}
                  hilighted={hilighted}
                  addHilighted={addHilighted}
                  clearHilighted={clearHilighted}
                />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <h2>
                  Edges
                </h2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <EdgeList
                  nodes={model.nodes}
                  edges={model.edges}
                  addEdge={addEdge}
                  removeEdge={removeEdge}
                  updateEdge={updateEdge}
                  selected={selected}
                  addSelected={addSelected}
                  clearSelected={clearSelected}
                  hilighted={hilighted}
                  addHilighted={addHilighted}
                  clearHilighted={clearHilighted}
                />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <h2>
                  Loops
                </h2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <LoopList
                  loops={model.loops}
                  removeLoop={removeLoop}
                  addSelected={addSelected}
                  clearSelected={clearSelected}
                  hilighted={hilighted}
                  addHilighted={addHilighted}
                  clearHilighted={clearHilighted}
                />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <h2>
                  Boundary Conditions
                </h2>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <BoundaryConditionList
                  boundaryConditions={model.boundaryConditions}
                  addBoundaryCondition={addBoundaryCondition}
                  removeBoundaryCondition={removeBoundaryCondition}
                  updateBoundaryCondition={updateBoundaryCondition}
                  selected={selected}
                  addSelected={addSelected}
                  clearSelected={clearSelected}
                  hilighted={hilighted}
                  addHilighted={addHilighted}
                  clearHilighted={clearHilighted}
                  startBcGeometrySelection={startBcGeometrySelection}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </DrawerContent>
      </Drawer>
      <Help isOpen={isHelpOpen} onClose={onHelpClose} mainUiStackDirection={mainUiStackDirection}/>
      <Card flex="2" height="100%">
        <Box>
          <Box position="absolute" zIndex="11">
            {
              isAdminMode &&
              <VStack position="relative" zIndex={10} alignItems="stretch">
                <IconButton
                  size={buttonSize}
                  aria-label='Open Details'
                  icon={<HamburgerIcon />}
                  onClick={toggleSideBar}
                  m="4"
                />
                <Menu>
                  <MenuButton size={buttonSize} as={Button}>
                    Draw
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setInteractionMode(InteractionMode.Select)}>Select</MenuItem>
                    <MenuItem onClick={() => setInteractionMode(InteractionMode.NodeDrawing)}>Node</MenuItem>
                    <MenuItem onClick={() => setInteractionMode(InteractionMode.EdgeDrawing)}>Edge</MenuItem>
                    <MenuItem onClick={() => setInteractionMode(InteractionMode.ArcDrawing)}>Arc</MenuItem>
                    <MenuItem onClick={() => setInteractionMode(InteractionMode.LoopCreation)}>Loop</MenuItem>
                  </MenuList>
                </Menu>
                <Menu>
                  <MenuButton size={buttonSize} as={Button}>
                    Preprocess
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        const res = generateMesh(model, 0.1);
                        setMeshData(res);
                      }}
                      disabled={gmshReady}>Generate Mesh</MenuItem>
                    <MenuItem onClick={() => { setMeshData({ svg: "", json: "", uuidToNodes: null }); }}>Clear Mesh</MenuItem>
                    <MenuItem
                      onClick={() => {
                        const newBC = new BoundaryCondition({ kind: { type: "fixed", data: {} } });
                        addBoundaryCondition(newBC);
                        setInteractionMode(InteractionMode.SelectBCGeometry);
                        clearSelected();
                        addSelected(newBC.id_);
                      }}>
                      Boundary condition
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Button size={buttonSize} colorScheme={interactionMode === InteractionMode.Trace ? "teal" : undefined} onClick={() => setInteractionMode(InteractionMode.Trace)}>Trace</Button>
                <Menu>
                  <MenuButton size={buttonSize} as={Button}>Clear</MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => { setSeedPoints([]); }}>Clear traces</MenuItem>
                    <MenuItem onClick={() => { clearAll(); }}>Clear all</MenuItem>
                  </MenuList>
                </Menu>
                <Button size={buttonSize} onClick={onSaveToJSON}>Save to JSON</Button>
                <Button size={buttonSize} onClick={() => fileInputRef.current?.click()}>Load JSON</Button>
                <input
                  type="file"
                  accept=".json"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={onLoadJSON}
                />
                <Button size={buttonSize} onClick={() => setInteractionMode(InteractionMode.GameBoundaryCondition)}>Game boundary</Button>
              </VStack>
            }
            </Box>
          <Box position="absolute" zIndex="10" margin='10px' width="100%" top="10px">
            <Center>
              <Menu>
                <MenuButton rightIcon={isSolutionCorrect ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />} size={buttonSize} as={Button}>
                  Level {activePuzzleId + 1}
                </MenuButton>
                <MenuList>
                  {
                    puzzles.map((puzzle) => {
                      return <MenuItem justifyContent='space-around' onClick={() => { handleLoadPremade(puzzle.id); setActivePuzzleId(puzzle.id); }}>
                        <Box flex={2}>
                          <Text as={activePuzzleId === puzzle.id ? 'b' : undefined}>Level {puzzle.id + 1}</Text>
                        </Box>
                        <Box flex={1}>
                          {puzzle.isSolved ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />}
                        </Box>
                      </MenuItem>;
                    })
                  }
                </MenuList>
              </Menu>
              <Button leftIcon={<SettingsIcon/>}colorScheme='blue' size={buttonSize} onClick={() => {
                if (meshData.svg === "") return;
                if (meshData.uuidToNodes === null) return;
                const result = runPlaneStressAnalysis(meshData.json, model.boundaryConditions, meshData.uuidToNodes, model.nodes, model.edges);
                setSimulationObject(result);
              }}>
                Simulate
              </Button>
            </Center>
          </Box>
          <Box position="absolute" zIndex="11" margin="10px" top="10px" right="10px">
            <IconButton variant="outline" colorScheme="blue" aria-label='How to play' onClick={onHelpOpen} size={buttonSize} icon={<QuestionIcon/>}/>
          </Box>
          <Stack direction={mainUiStackDirection !== undefined ? mainUiStackDirection : "column"} height={isMobile ? "100dvh" : "100vh"} width="100%" gap="0">
            <Box position="relative" flex="1" minH="0" minW="0" height="100%" width="100%">
              <Canvas
                model={model}
                boundaryConditions={model.boundaryConditions}
                updateNode={updateNode}
                addNode={addNode}
                interactionMode={interactionMode}
                addSelected={addSelected}
                clearSelected={clearSelected}
                selected={selected}
                addHilighted={addHilighted}
                clearHilighted={clearHilighted}
                hilighted={hilighted}
                meshSVG={meshData.svg}
                addSeedPoint={addSeedPoint}
                svgTrajectories={svgTrajectories}
                createInteractiveBoundaryCondition={createInteractiveBoundaryCondition}
                updateBoundaryCondition={updateBoundaryCondition}
              />
              <Center>
                {
                  !isPopoverOpen && selected.length !== 0 && model.boundaryConditions.find((bc: BoundaryCondition) => bc.id_ === selected.at(0)) &&
                  <Card position="absolute" bottom="5px" boxShadow="base">
                    <CardBody padding="5px">
                      {
                        <SimpleBoundaryCondition
                          bc={model.boundaryConditions.find((bc: BoundaryCondition) => bc.id_ === selected.at(0)) as BoundaryCondition}
                          removeBoundaryCondition={removeBoundaryCondition}
                        />
                      }
                    </CardBody>
                  </Card>
                }
              </Center>
            </Box>
            <Box position="relative" flex="1" minH="0" minW="0" height="100%" width="100%">
              <Canvas
                model={model}
                boundaryConditions={[]}
                updateNode={updateNode}
                addNode={addNode}
                interactionMode={InteractionMode.Select}
                addSelected={(id: string) => { }}
                clearSelected={() => { }}
                selected={[]}
                addHilighted={(id: string) => { }}
                clearHilighted={() => { }}
                hilighted={[]}
                meshSVG={meshData.svg}
                addSeedPoint={(p: Point) => { }}
                svgTrajectories={referenceTrajectories}
                createInteractiveBoundaryCondition={(id: string) => { }}
                updateBoundaryCondition={(boundaryCondition: BoundaryCondition, props: BoundaryConditionProps) => { }}
              />
              <PopoverLevelFinished
                isOpen={isPopoverOpen}
                setIsOpen={setIsPopoverOpen}
                areAllLevelsComplete={puzzles.every(puzzle => puzzle.isSolved === true)}
                onPopoverAccepted={() => {
                  setIsPopoverOpen((previous) => {
                    return !previous;
                  });
                  setActivePuzzleId((activePuzzleId) => Math.min(activePuzzleId + 1, puzzles.length - 1));
                  setSimulationObject((previous) => null);
                  setSVGTrajectories("");
                  setIsSolutionCorrect(false);
                }}
                onPopoverRejected={() => {
                  setIsPopoverOpen((previous) => !previous);
                  setSimulationObject((previous) => null);
                  setModel((previous: Model) => new Model(previous.nodes, previous.edges, previous.loops, []));
                  setSVGTrajectories("");
                  setIsSolutionCorrect(false);
                }}
                onRestartGameRequested={() => {
                  setIsPopoverOpen((previous) => !previous);
                  setSimulationObject((previous) => null);
                  setActivePuzzleId(0);
                  setPuzzles(puzzleData);
                  setSVGTrajectories("");
                  setIsSolutionCorrect(false);
                }}
              />
            </Box>
          </Stack>
              <Center>
                <Box borderRadius="md" position="absolute" bottom="15px" height={colorBarSize} width="90%" maxW="500px" boxShadow="lg" overflow="hidden" onClick={ () => setColorMap((previous) => previous === Colormap.bwr ? Colormap.turbo : Colormap.bwr)}>
                  <Colorbar colors={colors}/>
                </Box>
              </Center>
        </Box>
      </Card>
    </HStack>
  );
}

export default App;
