import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, StackDirection, Divider, Box } from "@chakra-ui/react";

export interface HelpProps{
    isOpen: boolean
    onClose: () => void
    mainUiStackDirection : StackDirection | undefined
};

export const Help = (
    {
    isOpen,
    onClose,
    mainUiStackDirection
    } : HelpProps
) => {
    return (
        <>
        <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How to play</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p><b>Goal</b></p>
            <p>Your goal is to figure out what are the boundary conditions for a given distribution of principal stress trajectories.</p>
            <p>On the {mainUiStackDirection !== undefined && mainUiStackDirection === "column" ? "bottom" : "right"} half, you see the trajectories. On the other half, you need to draw the boundary conditions.</p>
            <Divider/>
            <Box marginTop="10px" marginBottom="10px">
            <p><b>Click on an edge to fix it</b></p>
            <video autoPlay loop>
                <source src="fix_bc.webm"/>
            </video>
            </Box>
            <Divider/>
            <Box marginTop="10px" marginBottom="10px">
            <p><b>Click and drag an edge to apply a force</b></p>
            <video autoPlay loop>
                <source src="force_bc.webm"/>
            </video>
            </Box>
            <Divider/>
            <Box marginTop="10px" marginBottom="10px">
            <p><b>Click on an edge to select and then click Remove to remove a boundary condition</b></p>
            <video autoPlay loop>
                <source src="remove_bc.webm"/>
            </video>
            </Box>
            <Divider/>
            <Box marginTop="10px" marginBottom="10px">
            <p><b>Click on Simulate to submit a simulation</b></p>
            <video autoPlay loop>
                <source src="simulate.webm"/>
            </video>
            </Box>
            <Divider/>
            <Box marginTop="10px" marginBottom="10px">
            <p><b>Click on the Colorbar to toggle between colormaps</b></p>
            <video autoPlay loop>
                <source src="change_cmap.webm"/>
            </video>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
        </>
    );
};