import { useEffect, useState } from "react";

const getWindowHeight = () => {
    const {innerWidth, innerHeight} = window;
    return innerHeight;
};

export function useWindowHeight()
{

    const [windowHeight, setWindowHeight] = useState<number>(getWindowHeight());

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(getWindowHeight());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    return windowHeight;
}