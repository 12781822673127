import { Box, Button, HStack, Tag } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { BoundaryCondition } from "../model/boundarycondition";
import { DeleteIcon } from "@chakra-ui/icons";

export interface SimpleBoundaryConditionProps{
    bc: BoundaryCondition,
    removeBoundaryCondition: (arg0: BoundaryCondition) => void
}

export const SimpleBoundaryCondition = ({
            bc,
            removeBoundaryCondition
}: SimpleBoundaryConditionProps) => {
    const buttonSize = useBreakpointValue({base: 'xs', lg: 'lg'});
    return (
        <Box>
            <HStack>
                <Tag colorScheme="blue">{bc.kind_.type === "fixed" ? "Fixed" : "Force"}</Tag>
                <Tag colorScheme="blue">u={bc.kind_.data.u}</Tag>
                <Tag colorScheme="blue">v={bc.kind_.data.v}</Tag>
                <Button size={buttonSize} leftIcon={<DeleteIcon/>} colorScheme="red" onClick={() => removeBoundaryCondition(bc)}>Remove</Button>
            </HStack>
        </Box>
    );
}